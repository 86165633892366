
    
    .containerFull {
        height: 100vh;
        width: 100%;
        background-image: url("../../assets/images/bg-2_bis.jpg");
        background-position: top top;
        background-size: cover;
    }
    
    #wrapper{
    height: 100vh ;
    width:100%;
    display:table;
    overflow:hidden;
    }
    #contenedor{
    display:table-cell;
    width:100%;
    vertical-align:middle;
    text-align:center;
    }
    #contenido{
    display:inline-block;
    margin:0 auto;
    }

    #contenido h1, h5 {
        color: #d3d3d3;
        letter-spacing: 3px;
    }